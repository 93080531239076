import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable, inject } from '@angular/core'
import { environment } from '../../../../environments/environment'
import { Observable, catchError, of, switchMap, throwError } from 'rxjs'
import { JwtHelperService } from '@auth0/angular-jwt'
import { AlertService } from '../alert/alert.service'
import { Router } from '@angular/router'
import { UserService } from '../user/user.service'

@Injectable({ providedIn: 'root' })
export class AuthService {
  private http = inject(HttpClient)
  private jwtHelperService = inject(JwtHelperService)
  private alertService = inject(AlertService)
  private router = inject(Router)
  private userService = inject(UserService)

  setToken({
    token,
    expiry,
    refreshToken,
  }: {
    token: string
    expiry: number
    refreshToken: string
  }) {
    localStorage.setItem('token', token)
    localStorage.setItem('expiry', (Date.now() + expiry * 1000).toString())
    localStorage.setItem('refresh_token', refreshToken)
  }

  getToken() {
    return {
      token: localStorage.getItem('token'),
      expiry: localStorage.getItem('expiry'),
      refreshToken: localStorage.getItem('refresh_token'),
    }
  }

  handleTokenError(error: any) {
    console.error(error)
    this.alertService.showNotification('warn', 'Invalid token. Please login')
    return throwError(() => new Error('Token error'))
  }

  singleSignOn(returnUrl: string = '/') {
    window.location.href = `${environment.wp_url}/oauth/authorize?response_type=code&client_id=${environment.wp_client_id}&state=${returnUrl}`
  }

  logout(returnUrl: string = '/') {
    localStorage.removeItem('token')
    localStorage.removeItem('expiry')
    localStorage.removeItem('refresh_token')
    this.userService.resetUserData()
    this.router.navigate(['login'], {queryParams: { return_url: returnUrl }})
  }

  getAuthorizationCode(code: string) {
    let body = new URLSearchParams()
    body.set('grant_type', 'authorization_code')
    body.set('code', code)
    body.set('client_id', environment.wp_client_id)
    body.set('client_secret', environment.wp_client_secret)

    return this.http
      .post(`${environment.wp_url}/oauth/token`, body.toString(), {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        params: new HttpParams().set(
          'cmp_bypass',
          '6fcd0d38e093472cad6c9bf0191c642b'
        ),
      })
      .pipe(
        catchError((error) => {
          console.error('Authorization code error:', error)
          return throwError(error)
        })
      )
  }

  refreshToken() {
    const { refreshToken } = this.getToken()
    if (!refreshToken) {
      return throwError('No refresh token available')
    }

    let body = new URLSearchParams()
    body.set('grant_type', 'refresh_token')
    body.set('refresh_token', refreshToken)
    body.set('client_id', environment.wp_client_id)
    body.set('client_secret', environment.wp_client_secret)

    return this.http
      .post(`${environment.wp_url}/oauth/token`, body.toString(), {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      })
      .pipe(
        catchError((error) => {
          console.error('Refresh token error:', error)
          return throwError(error)
        })
      )
  }

  isAuthenticated(): Observable<boolean> {
    const { expiry } = this.getToken()
    if (expiry && Date.now() / 1000 < parseInt(expiry)) {
      return of(true)
    } else {
      return this.refreshToken().pipe(
        switchMap((tokenResponse: any) => {
          const { access_token, expiry, refresh_token } = tokenResponse
          this.setToken({
            token: access_token,
            expiry,
            refreshToken: refresh_token,
          })
          return of(true)
        }),
        catchError(() => of(false))
      )
    }
  }
}
